import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import ResultsMenu from "../../components/ResultsMenu"

export default function Results() {
  useEffect(() => {
    const isSSR = typeof window === "undefined"
    if (!isSSR) {
      window.location.href = "/resultat/2023"
    }
  })
  return (
    <Layout>
      <ResultsMenu />
    </Layout>
  )
}
